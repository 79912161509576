import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register GSAP plugins once at the top level
gsap.registerPlugin(ScrollTrigger);

// ASCII art for the console
const ASCII_ART = `
 :::::::: ::::::::::: ::::::::  :::::::::        ::::::::  :::    ::: ::::::::::: ::::::::::: :::       :::     :::     :::::::::  ::::::::::
:+:    :+:    :+:    :+:    :+: :+:    :+:      :+:    :+: :+:    :+:     :+:         :+:     :+:       :+:   :+: :+:   :+:    :+: :+:
+:+           +:+    +:+    +:+ +:+    +:+      +:+        +:+    +:+     +:+         +:+     +:+       +:+  +:+   +:+  +:+    +:+ +:+
+#++:++#++    +#+    +#+    +:+ +#++:++#+       +#++:++#++ +#++:++#++     +#+         +#+     +#+  +:+  +#+ +#++:++#++: +#++:++#:  +#++:++#
       +#+    +#+    +#+    +#+ +#+                    +#+ +#+    +#+     +#+         +#+     +#+ +#+#+ +#+ +#+     +#+ +#+    +#+ +#+
#+#    #+#    #+#    #+#    #+# #+#             #+#    #+# #+#    #+#     #+#         #+#      #+#+# #+#+#  #+#     #+# #+#    #+# #+#
 ########     ###     ########  ###              ########  ###    ### ###########     ###       ###   ###   ###     ### ###    ### ##########
`;

// Configuration using frozen objects for performance
const CONFIG = Object.freeze({
  ANIMATION: Object.freeze({
    DEFAULT_DURATION: 1.25,
    DEFAULT_EASE: "expo.inOut",
    BLUR_DURATION: 1,
    NAV_DURATION: 0.45,
    DEBOUNCE_DELAY: 200,
    BLUR_AMOUNT: 20, // Reduced from 100px for performance
  }),
  SCROLL: Object.freeze({
    LERP: 0.1,
    INFINITE: false,
    SMOOTH_WHEEL: true,
  }),
  DIRECTIONS: Object.freeze({
    NEXT: 1,
    PREV: -1,
  }),
});

// Centralized animation configurations
const ANIMATIONS = Object.freeze({
  picker: Object.freeze({
    open: Object.freeze({
      pickerContainer: {
        width: "260px",
        height: "25px",
        opacity: 1,
      },
      footerPicker: {
        width: "260px",
        height: "25px",
      },
    }),
    close: Object.freeze({
      pickerContainer: {
        width: 0,
        height: 0,
        opacity: 0,
      },
      footerPicker: {
        width: "16px",
        height: "16px",
      },
    }),
  }),
  nav: Object.freeze({
    open: Object.freeze({
      navContainer: { top: "0" },
      navBlur: { top: "0", delay: 0.05 },
      nav: {
        background: "hsla(0, 0%, 0%, 0.4)",
        pointerEvents: "auto",
        delay: 0.25,
      },
    }),
    close: Object.freeze({
      navContainer: { top: "-210px" },
      navBlur: { top: "-40%", delay: 0.05 },
      nav: {
        background: "hsla(0, 0%, 0%, 0.0)",
        pointerEvents: "none",
        delay: 0.25,
      },
    }),
  }),
});

// Central DOM cache for all elements
const elements = {
  cache: new Map(),

  init() {
    // Main elements
    this.cache.set("footerPicker", document.querySelector("#footerPicker"));
    this.cache.set("navToggle", document.querySelector("#navToggle"));
    this.cache.set(
      "pickerContainer",
      document.querySelector("#pickerContainer"),
    );
    this.cache.set("navContainer", document.querySelector("#navContainer"));
    this.cache.set("navBlur", document.querySelector("#navBlur"));
    this.cache.set("nav", document.querySelector("#nav"));
    this.cache.set("footerWave", document.querySelector("#footerWave"));
    this.cache.set("navWave", document.querySelector("#navWave"));
    this.cache.set("errorBanner", document.querySelector(".error-banner"));

    // Get slideshows
    this.cache.set("slideshows", [...document.querySelectorAll(".slide")]);

    // Get content elements
    this.cache.set("contentElements", [
      ...document.querySelectorAll(".content"),
    ]);
  },

  get(key) {
    return this.cache.get(key);
  },

  getAll(selector) {
    if (this.cache.has(selector)) {
      return this.cache.get(selector);
    }

    try {
      const elements = [...document.querySelectorAll(selector)];
      this.cache.set(selector, elements);
      return elements;
    } catch (error) {
      console.error(`Invalid selector: ${selector}`, error);
      return [];
    }
  },

  getElement(selector, context = document) {
    const cacheKey =
      context === document ? selector : `${context.id || "ctx"}-${selector}`;

    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey);
    }

    try {
      const element = context.querySelector(selector);
      if (element) {
        this.cache.set(cacheKey, element);
      }
      return element;
    } catch (error) {
      console.error(`Invalid selector: ${selector}`, error);
      return null;
    }
  },
};

// Global state using a Proxy for reactivity
const state = new Proxy(
  {
    lenis: null,
    isPickerOpen: false,
    isNavOpen: true,
    lastScrollDirection: "up",
    isAnimating: false,
    lastWidth: window.innerWidth,
    lastHeight: window.innerHeight,
    eventHandlers: new Map(), // Store event handlers for cleanup
  },
  {
    set(target, property, value) {
      const oldValue = target[property];
      target[property] = value;

      // Add any state change reactions here if needed
      if (property === "isAnimating" && oldValue !== value) {
        // Example: toggle a loading indicator
        // document.body.classList.toggle('is-animating', value);
      }

      return true;
    },
  },
);

/**
 * Utility functions using modern JS features
 */
const utils = {
  // Optimized debounce that returns a uniquely identifiable function
  debounce(func, wait) {
    let timeoutId;

    const debouncedFn = function (...args) {
      const later = () => {
        clearTimeout(timeoutId);
        func(...args);
      };

      clearTimeout(timeoutId);
      timeoutId = setTimeout(later, wait);
    };

    // Store original function for cleanup
    debouncedFn.originalFn = func;

    return debouncedFn;
  },

  // Add event listeners more concisely with cleanup function
  addEventListeners(element, events, handler, options = { passive: true }) {
    if (!element) return null;

    events.forEach((event) => {
      element.addEventListener(event, handler, options);
    });

    // Return cleanup function
    return () => {
      events.forEach((event) => {
        element.removeEventListener(event, handler);
      });
    };
  },

  // Register an event handler with automatic tracking for cleanup
  registerEventHandler(
    key,
    element,
    events,
    handler,
    options = { passive: true },
  ) {
    // Remove existing handler if present
    this.removeEventHandler(key);

    // Add new handler
    const cleanup = this.addEventListeners(element, events, handler, options);

    if (cleanup) {
      state.eventHandlers.set(key, cleanup);
    }

    return cleanup;
  },

  // Remove a registered event handler
  removeEventHandler(key) {
    if (state.eventHandlers.has(key)) {
      const cleanup = state.eventHandlers.get(key);
      cleanup();
      state.eventHandlers.delete(key);
      return true;
    }
    return false;
  },

  // Clean up all registered event handlers
  removeAllEventHandlers() {
    state.eventHandlers.forEach((cleanup) => cleanup());
    state.eventHandlers.clear();
  },

  // Show error message to user
  showError(message) {
    const errorBanner = elements.get("errorBanner");
    if (errorBanner) {
      errorBanner.textContent = message;
      errorBanner.style.display = "block";

      // Auto-hide after 5 seconds
      setTimeout(() => {
        errorBanner.style.display = "none";
      }, 5000);
    }
  },
};

/**
 * Scroll Controller using modern async patterns
 */
class ScrollController {
  static init() {
    try {
      if (typeof Lenis !== "function") {
        throw new Error("Lenis is not loaded");
      }

      state.lenis = new Lenis(CONFIG.SCROLL);

      // Use RAF for better performance
      state.lenis.on("scroll", () => {
        if (!state.isAnimating) {
          requestAnimationFrame(ScrollTrigger.update);
        }
      });

      // Use named function for clarity and optimization
      const scrollFrame = (time) => {
        state.lenis?.raf(time);
        requestAnimationFrame(scrollFrame);
      };

      requestAnimationFrame(scrollFrame);
      return true;
    } catch (error) {
      console.error("Failed to initialize smooth scrolling:", error);
      utils.showError("Smooth scrolling couldn't be initialized");
      return false;
    }
  }

  static destroy() {
    if (state.lenis) {
      state.lenis.destroy();
      state.lenis = null;
    }
  }
}

/**
 * Enhanced Slideshow Class with proper error handling and modern JS
 */
class Slideshow {
  constructor(element) {
    // Validate element
    if (!(element instanceof HTMLElement)) {
      throw new Error("Invalid slideshow element");
    }

    this.element = element;
    this.slides = [...element.querySelectorAll(".slide__content")];

    if (!this.slides.length) {
      throw new Error("No slides found in slideshow");
    }

    // Cache slide inner elements
    this.slidesInner = this.slides
      .map((slide) => slide.querySelector(".slide__img"))
      .filter(Boolean);

    // Set initial state
    this.current = 0;
    this.slidesTotal = this.slides.length;
    this.slides[0]?.classList.add("slide__current");

    // Create timeline once and reuse it
    this.timeline = gsap.timeline({
      paused: true,
      defaults: {
        duration: CONFIG.ANIMATION.DEFAULT_DURATION,
        ease: CONFIG.ANIMATION.DEFAULT_EASE,
      },
    });
  }

  navigate(direction) {
    // Guard clauses for early returns
    if (state.isAnimating || !this.slides.length) return false;

    state.isAnimating = true;
    const previous = this.current;

    // Calculate next index with remainder operator
    this.current =
      (this.current + direction + this.slidesTotal) % this.slidesTotal;

    // Destructure for clarity
    const [currentSlide, upcomingSlide] = [
      this.slides[previous],
      this.slides[this.current],
    ];

    const [currentInner, upcomingInner] = [
      this.slidesInner[previous],
      this.slidesInner[this.current],
    ];

    // Another guard clause for validation
    if (!currentSlide || !upcomingSlide) {
      state.isAnimating = false;
      return false;
    }

    // Clear and reuse timeline
    this.timeline.clear();

    // Set initial state
    upcomingSlide.classList.add("slide__current");
    gsap.set(upcomingSlide, { zIndex: 99 });

    // Build animation timeline
    this.timeline
      .addLabel("start", 0)
      .to(
        currentSlide,
        {
          duration: 0.65,
          ease: "power1.inOut",
          scale: 0.9,
          borderRadius: "2rem",
          autoAlpha: 0.2,
        },
        "start",
      )
      .to(
        currentSlide,
        {
          yPercent: -direction * 20,
          autoAlpha: 0,
        },
        "start+=0.1",
      )
      .fromTo(
        upcomingSlide,
        {
          autoAlpha: 1,
          scale: 1,
          borderRadius: "inherit",
          yPercent: direction * 100,
        },
        { yPercent: 0 },
        "start+=0.1",
      )
      .fromTo(
        upcomingInner,
        { yPercent: -direction * 50 },
        { yPercent: 0 },
        "start+=0.1",
      )
      .eventCallback("onComplete", () => {
        currentSlide.classList.remove("slide__current");
        gsap.set(upcomingSlide, { zIndex: 1 });
        state.isAnimating = false;
      });

    // Play the timeline
    this.timeline.play(0);
    return true;
  }

  // Arrow functions to maintain this binding
  next = () => this.navigate(CONFIG.DIRECTIONS.NEXT);
  prev = () => this.navigate(CONFIG.DIRECTIONS.PREV);
}

/**
 * UI Controller as a module pattern with modern features
 */
const UIController = {
  // Event handler storage for cleanup
  handlers: {},

  initColorPicker() {
    try {
      const picker = new iro.ColorPicker("#picker", {
        width: 250,
        color: "hsl(256, 0%, 0%)",
        layout: [
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "hue",
              handleRadius: 9,
              padding: 0,
              margin: 0,
              borderColor: "var(--color-light)",
              borderWidth: 3,
            },
          },
        ],
      });

      // Initial color update
      this.updateColors(89);

      // Throttle color updates for performance
      let frameId = null;
      const updateColorHandler = (color) => {
        if (frameId) cancelAnimationFrame(frameId);
        frameId = requestAnimationFrame(() => {
          this.updateColors(color.hsl.h);
          frameId = null;
        });
      };

      picker.on(["color:init", "color:change"], updateColorHandler);
      return true;
    } catch (error) {
      console.error("Failed to initialize color picker:", error);
      utils.showError("Color picker couldn't be initialized");
      return false;
    }
  },

  updateColors(hue) {
    // Use CSS variables for better performance
    const root = document.documentElement;

    // Batch all DOM updates in one requestAnimationFrame
    requestAnimationFrame(() => {
      root.style.setProperty("--color-accent", `oklch(93.57% 0.0443 ${hue})`);
      root.style.setProperty("--color-accent-2", `oklch(99.25% 0.0443 ${hue})`);
      root.style.setProperty(
        "--color-light",
        `oklch(98.25% 0.0148 ${(hue + 326) % 360})`,
      );
      root.style.setProperty(
        "--color-dark",
        `oklch(77.08% 0.0235 ${(hue + 162) % 360})`,
      );
      root.style.setProperty(
        "--color-darkest",
        `oklch(16.15% 0.0241 ${(hue - 2) % 360})`,
      );
    });
  },

  initToggles() {
    const footerPicker = elements.get("footerPicker");
    const navToggle = elements.get("navToggle");

    // Named handlers for proper cleanup
    this.handlers.togglePicker = (e) => {
      e.preventDefault();
      this.togglePicker();
    };

    this.handlers.toggleNav = (e) => {
      e.preventDefault();
      this.toggleNav();
    };

    // Register handlers with cleanup functions
    if (footerPicker) {
      utils.registerEventHandler(
        "footerPicker",
        footerPicker,
        ["click", "touchstart"],
        this.handlers.togglePicker,
      );
    }

    if (navToggle) {
      utils.registerEventHandler(
        "navToggle",
        navToggle,
        ["click"],
        this.handlers.toggleNav,
      );
    }
  },

  togglePicker() {
    const footerPicker = elements.get("footerPicker");
    const footerWave = elements.get("footerWave");
    const pickerContainer = elements.get("pickerContainer");

    if (!footerPicker || !pickerContainer) return;

    // Optimized blur animation
    if (footerWave) {
      gsap.to(footerWave, {
        filter: `blur(${CONFIG.ANIMATION.BLUR_AMOUNT}px)`,
        opacity: 0,
        duration: CONFIG.ANIMATION.BLUR_DURATION,
        ease: "power3.out",
        onComplete: () => gsap.set(footerWave, { display: "none" }),
      });
    }

    // Use the centralized animation configs
    const targetState = !state.isPickerOpen;
    const config = ANIMATIONS.picker[targetState ? "open" : "close"];

    // Animate elements
    gsap.to(pickerContainer, {
      ...config.pickerContainer,
      duration: CONFIG.ANIMATION.NAV_DURATION,
      ease: "power3.out",
    });

    gsap.to(footerPicker, {
      ...config.footerPicker,
      duration: CONFIG.ANIMATION.NAV_DURATION,
      ease: "power3.out",
    });

    // Update state and toggle class
    state.isPickerOpen = targetState;
    footerPicker.classList.toggle("active");
  },

  toggleNav() {
    const navToggle = elements.get("navToggle");
    const navWave = elements.get("navWave");
    const navContainer = elements.get("navContainer");
    const navBlur = elements.get("navBlur");
    const nav = elements.get("nav");

    if (!navToggle || !navContainer || !nav) return;

    // Optimized blur animation
    if (navWave) {
      gsap.to(navWave, {
        filter: `blur(${CONFIG.ANIMATION.BLUR_AMOUNT}px)`,
        opacity: 0,
        duration: CONFIG.ANIMATION.BLUR_DURATION,
        ease: "power3.out",
        onComplete: () => gsap.set(navWave, { display: "none" }),
      });
    }

    // Use the centralized animation configs
    const targetState = !state.isNavOpen;
    const config = ANIMATIONS.nav[targetState ? "open" : "close"];

    // Animate all elements using cached references
    gsap.to(navContainer, {
      ...config.navContainer,
      duration: CONFIG.ANIMATION.NAV_DURATION,
      ease: "power3.out",
    });

    if (navBlur) {
      gsap.to(navBlur, {
        ...config.navBlur,
        duration: CONFIG.ANIMATION.NAV_DURATION,
        ease: "power3.out",
      });
    }

    gsap.to(nav, {
      ...config.nav,
      duration: CONFIG.ANIMATION.NAV_DURATION,
      ease: "power3.out",
    });

    // Update state and toggle class
    state.isNavOpen = targetState;
    navToggle.classList.toggle("navbar__active");
  },

  // Cleanup method for proper resource management
  cleanup() {
    // Remove all event handlers
    Object.values(this.handlers).forEach((handler) => {
      if (typeof handler.cleanup === "function") {
        handler.cleanup();
      }
    });

    this.handlers = {};
  },
};

/**
 * Scroll Animations with performance optimizations
 */
const ScrollAnimations = {
  init() {
    // Get content elements from cache
    const contentElements = elements.get("contentElements");
    if (!contentElements || !contentElements.length) return;

    // Create animations for each element
    contentElements.forEach((el, position) => {
      const isLast = position === contentElements.length - 1;

      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top top",
            end: "+=100%",
            scrub: true,
          },
        })
        .to(el, {
          ease: "none",
          scale: 0.9,
          rotateX: "-35deg",
          borderRadius: "2.85rem",
          filter: isLast
            ? "none"
            : "brightness(0.2) saturate(300%) blur(1.5px)",
          "--shadowpercent": isLast ? "0%" : "115%",
          "--shadowoffset": isLast ? "0%" : "15%",
          startAt: {
            filter: "brightness(1) saturate(100%)",
            borderRadius: "0.5rem",
          },
        });
    });

    // Initialize scroll-based nav toggle
    this.initScrollDirectionDetection();
  },

  initScrollDirectionDetection() {
    ScrollTrigger.create({
      start: "top top",
      end: "bottom bottom",
      onUpdate: (self) => {
        const currentScrollDirection = self.direction === 1 ? "down" : "up";

        if (currentScrollDirection !== state.lastScrollDirection) {
          if (currentScrollDirection === "down" && state.isNavOpen) {
            UIController.toggleNav();
          }
          state.lastScrollDirection = currentScrollDirection;
        }
      },
    });
  },

  // Cleanup method
  cleanup() {
    // Kill all scroll triggers
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  },
};

// Create an efficient resize handler outside of any function
const handleResize = utils.debounce(() => {
  // Only refresh if dimensions actually changed
  if (
    window.innerWidth !== state.lastWidth ||
    window.innerHeight !== state.lastHeight
  ) {
    state.lastWidth = window.innerWidth;
    state.lastHeight = window.innerHeight;
    ScrollTrigger.refresh();
  }
}, CONFIG.ANIMATION.DEBOUNCE_DELAY);

/**
 * Helper function to initialize all slideshows
 */
function initSlideshows() {
  const slideshows = elements.get("slideshows");
  if (!slideshows || !slideshows.length) return;

  // Store slideshow instances for potential cleanup
  const instances = [];

  slideshows.forEach((element, index) => {
    try {
      const slideshow = new Slideshow(element);
      instances.push(slideshow);

      const prevButton = document.querySelector(
        `.slide__nav__item__prev[data-slideshow="${index}"]`,
      );
      const nextButton = document.querySelector(
        `.slide__nav__item__next[data-slideshow="${index}"]`,
      );

      // Register event handlers with cleanup functions
      if (prevButton) {
        utils.registerEventHandler(
          `slideshow-prev-${index}`,
          prevButton,
          ["click"],
          slideshow.prev,
        );
      }

      if (nextButton) {
        utils.registerEventHandler(
          `slideshow-next-${index}`,
          nextButton,
          ["click"],
          slideshow.next,
        );
      }
    } catch (error) {
      console.error(`Failed to initialize slideshow ${index}:`, error);
      utils.showError("One or more slideshows couldn't be initialized");
    }
  });

  return instances;
}

/**
 * Main initialization function with comprehensive error handling
 */
function init() {
  try {
    console.log("Initializing application...");

    // Initialize DOM cache first
    elements.init();

    // Initialize components
    const scrollInitialized = ScrollController.init();
    if (!scrollInitialized) {
      console.warn("Continuing without smooth scrolling");
    }

    // Initialize other components
    ScrollAnimations.init();
    UIController.initColorPicker();
    UIController.initToggles();

    // Initialize slideshows
    const slideshowInstances = initSlideshows();

    // Set up resize handler with dimensions tracking
    state.lastWidth = window.innerWidth;
    state.lastHeight = window.innerHeight;
    window.addEventListener("resize", handleResize, { passive: true });

    // Initial ScrollTrigger refresh
    ScrollTrigger.refresh();

    console.log("Application initialized successfully");
    console.log(ASCII_ART);

    // Return instances for potential access
    return {
      slideshows: slideshowInstances,
    };
  } catch (error) {
    console.error("Failed to initialize application:", error);
    utils.showError(
      "Application initialization failed. Please refresh the page.",
    );

    // Try to recover gracefully
    cleanup();
    return null;
  }
}

/**
 * Comprehensive cleanup function for proper resource management
 */
function cleanup() {
  try {
    console.log("Cleaning up application resources...");

    // Clean up scroll controller
    ScrollController.destroy();

    // Clean up animations
    ScrollAnimations.cleanup();

    // Clean up UI controller
    UIController.cleanup();

    // Remove event listeners
    utils.removeAllEventHandlers();
    window.removeEventListener("resize", handleResize);

    // Kill any remaining GSAP animations
    gsap.killTweensOf("*");

    console.log("Cleanup completed successfully");
  } catch (error) {
    console.error("Error during cleanup:", error);
  }
}

// Event listeners with proper options
document.addEventListener("DOMContentLoaded", init, { passive: true });
window.addEventListener("beforeunload", cleanup);

// Error boundary with detailed error handling
window.addEventListener("error", (event) => {
  console.error("Global error caught:", event.error);
  console.error("Error message:", event.message);
  console.error(
    "Error source:",
    event.filename,
    "Line:",
    event.lineno,
    "Column:",
    event.colno,
  );

  // Show user-friendly error message
  utils.showError("Something went wrong. Try refreshing the page.");

  // Attempt cleanup
  cleanup();
});

// Export for potential module usage
export {
  ScrollController,
  Slideshow,
  UIController,
  ScrollAnimations,
  init,
  cleanup,
};
